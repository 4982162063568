<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div></div>
        <div class="col-12 mt-2">
          <div>
            <router-link to="/skip-request-listing">
              <button class="btn btn-success btn btn-success rounded-pill mb-2">
                Back
              </button>
            </router-link>
          </div>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Skip Detail</h4>
          </div>
        </div>

        <div class="card-body">
          <div>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                id="input-group-1"
                label="User Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="selected"
                  readonly
                  placeholder="Enter Space"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label="Space:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="space"
                  readonly
                  placeholder="Enter Space"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label="Size:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="size"
                  readonly
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Expiry Date:"
                label-for="input-2"
              >
                <b-form-datepicker
                  readonly
                  v-model="expirydate"
                  class="mb-2"
                ></b-form-datepicker>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="ZipCode:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="zipcode"
                  type="text"
                  readonly
                  placeholder="Enter ZipCode"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Price for Skip:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="price"
                  type="text"
                  readonly
                  placeholder="Enter your price"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="description"
                  readonly
                  placeholder="Enter description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Instruction:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="instruction"
                  placeholder="Enter instruction here..."
                  rows="3"
                  readonly
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="CoverImage:"
                label-for="input-2"
              >
                <img
                  style="width: 140px;
    height: 140px;
    border-radius: 16px;"
                  :src="getLogo(this.coverImage)"
                  alt=""
                />
              </b-form-group>
            </b-form>
          </div>
        </div>
      </div>
      <br /><br />

      <div class="card">
        <div class="col-12 mt-2">
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>

          <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            class="alert alert-success"
          >
            {{ alertText }}
          </div>
          <br />
          <!-- <b-form-group id="input-group-2" label="Upload Images:" label-for="input-2">
              <input type="file" ref="file" multiple="multiple" @change="submitFiles">
              </b-form-group>

              <b-container fluid class="p-4 bg-dark" v-if="imagesShow">
  <b-row>
    <b-col-4 v-for="(image, key) in imagesArray" :key="key" class="p-3">
        <button style="color:white" class="close ml-1" @click.prevent="removeImage(image)">&times;</button>
      <b-img style="height: 150px;max-width: 165px;"  thumbnail fluid :src="getLogo(image)" alt="Image 1"></b-img>
    </b-col-4>
  </b-row>
</b-container> -->

          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Images</h4>
          </div>
        </div>

        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>

          <div class="table-responsive mb-0">
            <b-table
              :items="loans"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(image_name)="loans">
                <b-img
                  style="height: 70px;width: 72px;border-radius: 50%;"
                  thumbnail
                  fluid
                  :src="getLogo(loans.item.image_name)"
                  alt="Image 1"
                ></b-img>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import axios from "axios";
import VerficationModal from "./VerficationModal";

export default {
  components: {
    VerficationModal,
  },
  data() {
    return {
      size: null,
      id: "",
      selected: null,
      user: null,
      skipId: "",
      users: [],
      expirydate: "",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "id",
      imagesShow: false,
      filterOn: [],
      showDeleteModal: false,
      sortDesc: true,
      fields: [{ key: "image_name", label: "Image", sortable: true }],
      showModal: false,
      imagesArray: [],
      loans: [],
      description: "",
      instruction: "",
      coverimageUrl: null,
      coverImage: null,
      isShowModel: false,
      alertText: "",
      alert: false,
      space: "",
      price: "",
      zipcode: "",
      sizes: [{ text: "Select One", value: null }, "Small", "Medium", "Large"],
      show: true,
    };
  },
  computed: {
    rows() {
      return this.loans.length;
    },
  },

  mounted() {
    this.skipId = JSON.parse(localStorage.getItem("skipId"));
    this.getAllCustomer();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      try {
        this.addLoaderClasses("save");
        const formData = new FormData();
        formData.append("user_id", this.selected);
        formData.append("id", this.id);
        formData.append("size", this.size);
        formData.append("space", this.space);
        formData.append("zipcode", this.zipcode);
        formData.append("price", this.price);
        formData.append("description", this.description);
        formData.append("instruction", this.instruction);
        formData.append("expiry_date", this.expirydate);
        formData.append("images", this.imagesArray);
        formData.append("status", "admin");
        if (this.coverImage) {
          formData.append("coverImage", this.coverImage);
        }
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/skip-share-store", formData, {
          // headers,
        })
          .then((response) => {
            if (response.data.code == 200) {
              this.$router.push({ name: "skipshare" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        alert(err);
      }
    },
    coverImageShow(e) {
      const file = e.target.files[0];
      this.coverImage = file;
      this.coverimageUrl = URL.createObjectURL(file);
    },
    getAllCustomer() {
      try {
        const data = { id: this.skipId };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/skip-detail", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.selected = response.data.data.userDetail.username;
              this.loans = response.data.data.images;
              this.space = response.data.data.space;
              this.size = response.data.data.size;
              this.expirydate = response.data.data.expiry_date;
              this.zipcode = response.data.data.zipcode;
              this.price = response.data.data.price;
              this.description = response.data.data.description;
              this.instruction = response.data.data.instruction;
              this.coverImage = response.data.data.coverImage;
              this.loading = false;
              return response.data;
            }
          });
      } catch (err) {
        // console.log(err);
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    submitFiles() {
      Swal.fire({
        title: "Processing...",
        text: "Please wait while your request is being processed",
        buttons: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
      });

      let formData = new FormData();

      for (var i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        formData.append("images[" + i + "]", file);
      }

      ApiService.post(this.$path + "/save-multiple-image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            this.imagesArray = response.data.data;
            this.imagesShow = true;
            Swal.close();
          }
        })
        .catch(function(response) {
          if (response) {
            alert("Technical Issue");
          }
        });
    },

    removeImage(name) {
      this.imagesArray.splice(
        this.imagesArray.findIndex((x) => x === name),
        1
      );
      if (this.imagesArray.length == 0) {
        this.imagesShow = false;
      }
    },

    deleteUser(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = "delete-skip-image";
    },
    closeModal(value) {
      this.isShowModel = value;
    },
    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    deleteRow(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.item.id);
      if (index !== -1) {
        // this.loans.splice(index, 1);
        this.editRecord.images.splice(index, 1);
        this.loans = this.editRecord.images;
        localStorage.removeItem("editskip");
        localStorage.setItem("editskip", JSON.stringify(this.editRecord));
      }

      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.dataTables_length {
  display: none;
}
.dataTables_filter {
  display: none;
}
</style>
